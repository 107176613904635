import './Apps.css';
import app1 from './imgs/fogoverhills512.png';
import appIcon from './Deco/icons8-android-app-drawer-96.png';

function Apps() {
  return (
    <div className='AppsDiv'>
      <h3 id='appsHeader'><img id='appIcon' src={appIcon} />App</h3>
      <div class="banner">
        <a href="https://play.google.com/store/apps/details?id=com.paradisesettler.fogoverhills">
          <img id="foh" src={app1} alt="Cannot be found" title="Fog Over Hills app" />
        </a>
      </div>
    </div>
  )

}

export default Apps;
