import logo from './Deco/ps_logo_circle_t.png';
import './Head.css';

function Head() {

  
  return (
    <div>
      <header>
        <h1><a><img id="logo" src={logo} alt="Cannot be found" />Paradise Settler</a></h1>
      </header>
    </div>
  );
}

export default Head;