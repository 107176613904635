import React, { useState, useEffect } from 'react';
import CardProvider from './CardProvider';
import brush from './Deco/icons8-brush-64.png';
import store from './Deco/icons8-store-100.png';
import leftArrow from './Deco/icons8-left.png';
import rightArrow from './Deco/icons8-right.png';
import './Carousel.css';

function Carousel() {

    const [images, setImages] = useState([]);
    const [bPrevious, setbPrevious] = useState(0);
    const [previous, setPrevious] = useState(1);
    const [current, setCurrent] = useState(2);
    const [next, setNext] = useState(3);
    const [aNext, setaNext] = useState(4);
    const [currentSlide, setCurrentSlideStyle] = useState("artSlide fadeOut");


    useEffect(() => {
        const imagePromises = CardProvider.map((slide, index) => import(`./imgs/${slide.src}`).then((image) => image.default))
        Promise.all(imagePromises).then(resolvedImages => {
            setImages(resolvedImages);
        })

        const delayArtSlideAnimate = () => {
            if (currentSlide === "artSlide fadeOut") { setCurrentSlideStyle("artSlideFadeIn") }
        }

        const timeOutID = setTimeout(delayArtSlideAnimate, 400);

        return () => clearTimeout(timeOutID);

    }, [currentSlide]);


    const toPrevious = () => {
        setCurrentSlideStyle("artSlide fadeOut");
        setbPrevious(bPrevious === 0 ? images.length - 1 : bPrevious - 1);
        setPrevious(previous === 0 ? images.length - 1 : previous - 1);
        setCurrent(current === 0 ? images.length - 1 : current - 1);
        setNext(next === 0 ? images.length - 1 : next - 1);
        setaNext(aNext === 0 ? images.length - 1 : aNext - 1);
    }

    const toNext = () => {
        setCurrentSlideStyle("artSlide fadeOut");
        setbPrevious(bPrevious === images.length - 1 ? 0 : bPrevious + 1);
        setPrevious(previous === images.length - 1 ? 0 : previous + 1);
        setCurrent(current === images.length - 1 ? 0 : current + 1);
        setNext(next === images.length - 1 ? 0 : next + 1);
        setaNext(aNext === images.length - 1 ? 0 : aNext + 1);
    }
    console.log("Images: " + images);

    return (
        <div>
            <div className='enclosure' onContextMenu={(event) => { event.preventDefault(); return false }}>
                <h3 id='apHeading'><img id='brush' src={brush} />Acrylic paintings</h3>
                <div className='arrowDivL' onClick={toPrevious}>
                    <img className='arrow' src={leftArrow} />
                </div>
                {console.log("Images: ", images)}
                <img className='tertiarySlide' src={images[bPrevious]} title={CardProvider[bPrevious].title} alt='Image not loaded' />
                <img className='secondarySlide' src={images[previous]} title={CardProvider[previous].title} alt='Image not loaded' />
                <a href={'./FullArtwork/' + CardProvider[current].id}><img className={currentSlide} src={images[current]} title={CardProvider[current].title} alt='Image not loaded' /></a>
                <img className='secondarySlide' src={images[next]} title={CardProvider[next].title} alt='Image not loaded' />
                <img className='tertiarySlide' src={images[aNext]} title={CardProvider[aNext].title} alt='Image not loaded' />
                <div className='arrowDivR' onClick={toNext}>
                    <img className='arrow' src={rightArrow} />
                </div>
                <hr />
            </div>
            <a href='https://www.etsy.com/shop/ParadiseSettlerArt?ref=seller-platform-mcnav' title='Store'><img id='store' src={store} /></a>
        </div>


    )
}

export default Carousel;