import React, {useEffect} from 'react';
import './Deco/Deco.css';
import Background from './Deco/forest-morning-fall.jpg';
import homeBackground from './Deco/forest-morning-fall-feb-17.jpg';

const Privacy = () => {
    //const style = { background: `url(${Background})`, backgroundRepeat: "no-repeat", backgroundPosition: "center", backgroundAttachment: "fixed", backgroundSize: "cover", border: "2px solid orange", height: "100%", margin: "0", padding: "0" };

    useEffect(() => {
        const html = document.querySelector('html');

        html.style.backgroundImage = `url(${Background})`;
        html.style.backgroundRepeat = 'no-repeat';
        html.style.backgroundPosition = 'center';
        html.style.backgroundAttachment = 'fixed';
        html.style.backgroundSize = 'cover';

        return () => {
        html.style.backgroundImage = `url(${homeBackground})`;
        html.style.backgroundRepeat = 'no-repeat';
        html.style.backgroundPosition = 'center';
        html.style.backgroundAttachment = 'fixed';
        html.style.backgroundSize = 'cover';

        };

    }, []);

    return (
        <div>
            <h1>Fog Over Hills</h1>

            <div>
                <div className="PSCard">

                    <pics>
                        <p><b>Privacy Policy for Fog Over Hills</b></p>
                        <p>Effective Date: 08/02/2023</p>
                        <p>Thank you for using Fog Over Hills. This Privacy Policy explains how we handle and protect your information while using our app.</p>
                        <p><b>Information Collection and Use:</b></p>
                        <p>We want to emphasize that Fog Over Hills does not collect any personal information from its users. We do not request, store, or process any data that could be used to identify you or your device. However, the mediation advertisement software used within the app collects identifiers in order to operate as described in the following section called “Mediation Advertisement”.</p>
                        <p><b>Mediation Advertisements:</b></p>
                        <p>Fog Over Hills uses mediation advertisements to display relevant ads to users; specifically, IronSource. This mediation process integrates ads from multiple ad networks, maximizing the possibility of displaying appropriate advertisements that suit your interests and preferences. Therefore, as described in IronSource’s privacy  policy which you can find here [<a className='whiteLink' href="https://developers.ironsrc.com/ironsource-mobile/air/ironsource-mobile-privacy-policy/?_gl=1*vtfp3f*_gcl_au*NTE3ODMwNjAuMTY5MTAzMTcwMA..#step-2">IronSource privacy policy</a>], the ads shown through mediation may collect and make use of Advertising ID, IP address, and a self-created ID unique to a specific app, and IDs unique only to apps of the same developer.</p>
                        <p><b>Third-Party Services:</b></p>
                        <p>While Fog Over Hills serves advertisements through mediation, we want to assure you that we do not share any personal information with these third-party ad networks apart from the identifiers required for mediation with IronSource. The mediation process itself requires the collection and use of identifiers as mentioned in the “Mediation Advertisement” section of this privacy policy.</p>
                        <p><b>Data Security:</b></p>
                        <p>Even though we do not collect user data, we are committed to safeguarding any information you provide within the app. We employ industry-standard security measures to protect the integrity and confidentiality of our users' data.</p>
                        <p><b>Changes to This Privacy Policy:</b></p>
                        <p>We may update this Privacy Policy from time to time to reflect changes in our practices or for other operational, legal, or regulatory reasons. Any changes to the Privacy Policy will be posted on this page, and the revised version will be effective as of the stated "Effective Date."</p>
                        <p><b>Contact Us:</b></p>
                        <p>If you have any questions or concerns about this Privacy Policy or our app's privacy practices, please contact us at Paradisesettler@gmail.com.</p>
                    </pics>
                </div>
            </div>

        </div>
    )
}

export default Privacy;