import './Form.css';

function Form() {
    return (
        <div id='formDiv'>
            <script type="text/javascript" src="https://campaigns.zoho.com/js/zc.iframe.js"></script>
            <iframe frameborder="0" id="iframewin" width="100%" height="100%" src="https://zmp-glf.maillist-manage.com/ua/Optin?od=11287ecc6c8d48&zx=131c8e0ea&tD=1107479532a0d53c1&sD=1107479532a0d55a7"></iframe>
        </div>
    )

}
export default Form;