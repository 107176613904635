import './Footer.css';
import mail from './Deco/icons8-email-94.png';

function Footer () {
    return (
        <div>
            <footer>
                <p className='mailP'><img className='mailImg' src={mail}/> &nbsp; <a href='mailto: ps@paradisesettler.com'>ps@paradisesettler.com</a></p>
                <p>&copy; ParadiseSettler. All rights reserved. <cite> icons by <a id='referral' target="_blank" href="https://icons8.com">Icons8</a></cite></p>
            </footer>
        </div>
        )
}

export default Footer;
