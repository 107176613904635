import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './Home';
import Bio from './Bio';
import Privacy from './Privacy';
import FullArtwork from './SubComp/FullArtwork';

function PSRouting() {
    return (
        <Router>
            <Routes>
                <Route path="/" exact Component={Home} />
                <Route path="/Bio" Component={Bio} />
                <Route path='/fog-over-hills-privacy' Component={Privacy} />
                <Route path="/FullArtwork/:id" Component={FullArtwork} />
            </Routes>
        </Router>
    )
}

export default PSRouting;