import React, {useEffect} from 'react';
import './Deco/Deco.css';
import Background from './Deco/forest-morning-fall.jpg';
import homeBackground from './Deco/forest-morning-fall-feb-17.jpg';



function Bio() {
    useEffect(() => {
        const html = document.querySelector('html');

        html.style.backgroundImage = `url(${Background})`;
        html.style.backgroundRepeat = 'no-repeat';
        html.style.backgroundPosition = 'center';
        html.style.backgroundAttachment = 'fixed';
        html.style.backgroundSize = 'cover';

        return () => {
        html.style.backgroundImage = `url(${homeBackground})`;
        html.style.backgroundRepeat = 'no-repeat';
        html.style.backgroundPosition = 'center';
        html.style.backgroundAttachment = 'fixed';
        html.style.backgroundSize = 'cover';

        };

    }, []);
    return (

        <div>
            <h1>Biography</h1>
            <div>


                <div className='PSCard'>

                    <pics>
                        <p>Imagine<sp>...</sp> A place of your own, unknown by those even close. Your own, deep, where your mind stills<sp>...</sp> Can observe, can reminisce, can ponder. Art, where for some
                            are pulled to the top of a mountain and can watch a myriad of lifetimes pass before their eyes. While for others, moves them intimately close to witness a waterdrop
                            fall from a petal. Human beings like diamonds have multiple facets reflecting different gleams of experiences. These resurface at the glance of a beautiful painting.
                            So what will it be for you? A lost memory? Brimming wisdom? Only you know best.</p>

                        <p>My name is Samuel. I am grateful for being fortunate in having my art specially recognized on three occasions by the Light Space Time online art gallery; where
                            international competitions hosting over 500 artists are held, and a few receive special recognition. I paint in acrylic on cotton canvas since my father saw
                            potential and inspired me. A painting tells a story where the viewer has a key. For me, it is an opportunity to appreciate the details in life, and learn. It is as
                            listening to the voice of wisdom through the calm of a breeze, or the whirling call of a flute on a foggy morning.</p>

                        <p>I enjoy attending its halls,</p>

                        <p>And if you will, I hope, my art can help lead you there<sp>...</sp></p>

                        <p>Artist, writer and developer Samuel T.M. Kamanda was born in the Democratic Republic of the Congo. He has earned special recognition awards three times from Light Space
                            Time Online Art Gallery. Growing up, he has been mostly drawing in black and white then started getting involved with painting and colors when encouraged by his
                            father after finishing high school. He is fond of using shadows and outlines from his drawing experience as he believes they are indispensable as they surround
                            us every day and we always seek to capture what we see as best as we can. Growing up he has also loved making comic books in black and white as he loved the works
                            of the French "Dupuis" and Belgian "Herg<sp>é</sp>". Now working with colors, the same principles still influence him as he enjoys experimenting with colors and their
                            different tones like shadows to create three-dimensional effect. He started delving more in the details of drawings during his influence with late 90s Japanese art.
                        </p>

                    </pics>
                </div>
            </div>
        </div>
    )
}

export default Bio;