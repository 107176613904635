import Apps from './Apps';
import Head from './Head';
import Carousel from './Carousel';
import Form from './Form';
import Footer from './Footer';
import Background from './Deco/forest-morning-fall-feb-17.jpg';


function Home() {
    const style = {background: `url(${Background})`, backgroundRepeat: "no-repeat", backgroundPosition: "center fixed", backgroundSize: "cover", display: "flex", flexFlow: "column", height: "100%", border: "2px solid black"};

    return (
        <div>

            <Head />
            <Carousel />
            <Apps />
            <Form />
            <Footer />  
        </div>
    )
}

export default Home;