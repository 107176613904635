import React, {useState,useEffect} from 'react';
import { useParams } from 'react-router-dom';
import CardProvider from '../CardProvider';
import '../SubComp/FullArtwork.css';

function FullArtwork() {
    const [style, setStyle] = useState("defaultImgStyle");
    const [artImg, setArtImg] = useState();
    const {id} = useParams();

    useEffect(() => {
        const html = document.querySelector('html');
        html.style.backgroundImage = 'none';
        html.style.backgroundColor = 'lightblue';
        import(`../imgs/full/${CardProvider[id].src}`).then((image) => {setArtImg(image.default);});

    }, []);

    function changeStyle() {
        setStyle("enlargedImgStyle");
    }
    
    return (
        <div id="fullImageDiv" onContextMenu={(event) => {event.preventDefault(); return false;}}>
            <img onLoad={changeStyle} id={style} src={artImg} alt='Cannot be found' title={CardProvider[id].title} />
            <p><cite>{`${CardProvider[id].title}`}</cite> by Samuel T.M. Kamanda. Painted in {`${CardProvider[id].toc}`}.</p>
        </div>
    )
}

export default FullArtwork;