
const Cards = [
    {id: 0, title: "From Waves To Sky", src: 'from-waves-to-sky-nov-16.jpg', toc: "November 2016"},
    {id: 1, title: "Zongo, South West In Heart Of Africa", src: 'zongo,-south-west-in-heart-of-africa-jan-16.jpg', toc: "January 2016"},
    {id: 2, title: "Another Evening In The City", src: 'another-evening-in-the-city-aug-15.jpg', toc: "August 2015"}, 
    {id: 3, title: "Beyond Our Little World", src: "beyond-our-little-world-jan-15.jpg", toc: "January 2015"},
    {id: 4, title: "Breeze In The Valley", src: "breeze-in-the-valley-jul-16.jpg", toc: "July 2016"},  
    {id: 5, title: "While The World Forgets", src: 'while-the-world-forgets-sept-15.jpg', toc: "September 2015"},
    {id: 6, title: "Wind Haven The Flutist", src: 'wind-haven-the-flutist-sept-18.jpg', toc: "September 2018"},
    {id: 7, title: "Pause And Look", src: 'pause-and-look-aug-15.jpg', toc: "August 2015"}, 
    {id: 8, title: "Lacking Light", src: "lacking-light-dec-17.jpg", toc: "December 2017"},
    {id: 9, title: "Forest Morning Fall", src: "forest-morning-fall-feb-17.jpg", toc: "February 2017"},
];

export default Cards;
